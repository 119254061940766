import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { FC } from 'react';
import styled from 'styled-components';
import PageContainer from '../../pageContainers/PageContainer';

const IFrameContainer = styled.main`
  height: 100vh;
`;

const IFramePage: FC<PageProps<Queries.IFrameQuery>> = ({ data }) => {
  const iFrameEmbedLink = data?.prismicIframePage?.data?.iframeLink?.embed_url;
  const seo = {
    title: data?.prismicIframePage?.data?.Title || '',
    description: data?.prismicIframePage?.data?.Description || '',
    ogImage: data?.prismicIframePage?.data?.Image?.url,
    // schema: @todo add later
  } as const;

  return (
    <PageContainer seoProps={seo} noContentPadding={true}>
      <IFrameContainer>
        <iframe data-testid="iframe" src={iFrameEmbedLink || ''} height="100%" width="100%" />
      </IFrameContainer>
    </PageContainer>
  );
};

export default withPrismicPreview(IFramePage);

export const query = graphql`
  query IFrame($url: String!) {
    prismicIframePage(url: { eq: $url }) {
      id
      data {
        ...IFrameSEO
        iframeLink {
          embed_url
        }
      }
    }
  }
`;
